/*

    Name: Platform.sh theme

*/

.cm-s-platformsh.CodeMirror {
  background: #1a192b;
  color: #fff;
}
.cm-s-platformsh div.CodeMirror-selected {
  background: #303030;
}
.cm-s-platformsh .CodeMirror-line::selection,
.cm-s-platformsh .CodeMirror-line > span::selection,
.cm-s-platformsh .CodeMirror-line > span > span::selection {
  background: rgba(48, 48, 48, 0.99);
}
.cm-s-platformsh .CodeMirror-line::-moz-selection,
.cm-s-platformsh .CodeMirror-line > span::-moz-selection,
.cm-s-platformsh .CodeMirror-line > span > span::-moz-selection {
  background: rgba(48, 48, 48, 0.99);
}
.cm-s-platformsh .CodeMirror-gutters {
  background: #151515;
  border-right: 0px;
}
.cm-s-platformsh .CodeMirror-guttermarker {
  color: #ac4142;
}
.cm-s-platformsh .CodeMirror-guttermarker-subtle {
  color: #505050;
}
.cm-s-platformsh .CodeMirror-linenumber {
  color: #c9d0e4;
  font-family: Overpass Mono, monospace;
  text-align: right;
  font-size: 12px;
  line-height: 1.4;
}
.cm-s-platformsh .CodeMirror-cursor {
  border-left: 1px solid #b0b0b0;
}

.cm-s-platformsh span.cm-comment {
  color: #98a0ab;
}

.cm-s-platformsh span.cm-unit,
.cm-s-platformsh span.cm-def,
.cm-s-platformsh span.cm-atom {
  color: #ff9f9f;
}

.cm-s-platformsh span.cm-number {
  color: #71db82;
}

.cm-s-platformsh span.cm-operator,
.cm-s-platformsh span.cm-attribute {
  color: #c9d0e4;
}

.cm-s-platformsh span.cm-variable,
.cm-s-platformsh span.cm-variable-2,
.cm-s-platformsh span.cm-string-2,
.cm-s-platformsh span.cm-header,
.cm-s-platformsh span.cm-property {
  color: #ffffff;
}

.cm-s-platformsh span.cm-keyword,
.cm-s-platformsh span.cm-string {
  color: #23b7b7;
}

.cm-s-platformsh span.cm-meta {
  color: #a7c5ea;
}

.cm-s-platformsh span.cm-bracket {
  color: #e0e0e0;
}

.cm-s-platformsh span.cm-tag {
  color: #86b5ff;
}

.cm-s-platformsh span.cm-link {
  color: #aa759f;
}

.cm-s-platformsh span.cm-error {
  background: #ac4142;
  color: #b0b0b0;
}

.cm-s-platformsh .CodeMirror-activeline-background {
  background: #202020;
}
.cm-s-platformsh .CodeMirror-matchingbracket {
  text-decoration: underline;
  color: white !important;
}
